  @keyframes spinPlus {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(180deg);
    }
  }

  @keyframes spinNeg {
    from {
      transform: rotate(-180deg);
    }

    to {
      transform: rotate(0deg);
    }
  }

  .spin12-3 {
    animation-name: spinPlus;
    animation-duration: 500ms;
    animation-timing-function: ease-in;
  }

  .spin9-12 {
    animation-name: spinNeg;
    animation-duration: 500ms;
    animation-timing-function: ease-out;
  }

  @keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
  }

  @keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
  }

  .fade-in {
    animation-name: fade-in;
    animation-duration: 750ms;
    animation-timing-function: ease;
    opacity: 1;
  }

  .fade-out {
    animation-name: fade-out;
    animation-duration: 750ms;
    animation-timing-function: ease;
    opacity: 1;
  }
  
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  .header-desktop {
    width: 100vw;
    background-color: rgb(255,254,250);
    padding-bottom: 10px;
  }
  a {
    color: inherit;
    text-decoration: none;
  }

  .logo-section {
    width: 1000px;
    height: 121px;
    margin-inline: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
}

#logo {
    width: 154px;
    height: 121px;
    margin: 0;
}

hr {
    min-width: 1000px;
    width: calc(100% - 50px - 50px);
    margin-inline: auto;
    margin-block: 0;
    padding: 0;
    height: 1px;
}

.navbar {
    width: 1000px;
    margin-inline: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-inline: 10px;
}

.navbar ul {
    list-style: none;
    display: flex;
    padding: 0;
    
}

.left-list {
    color: rgb(105, 105, 105);
    font-size: 15px;
    font-family: sans-serif;
    line-height: 45px;
    gap: 30px;
    font-weight: 300;
}

.left-list li:hover {
  color: rgb(0, 0, 0);
  cursor: pointer;
}

.right-list {
    gap: 15px;
    font-size: 15px;
    font-family: sans-serif;
    line-height: 45px;
}

.right-list li:first-child {
    color: rgb(105, 105, 105);
    font-weight: 100;
    font-size: 14px;
}

@media only screen and (min-width: 1110px) {
  .hamburger, .header-mob {
    display: none;
  }
}

@media only screen and (max-width: 1110px) {

  body {
    padding-top: 80px;
  }

  .header-desktop, .navbar {
    display: none !important;
  }

  #logo {
    width: 80px;
    object-fit: contain;
    margin: 0;
}

  .hamburger {
    font-size: 30px;
    background-color: rgb(255,217,173);
    border: none;
    width: 40px;
    height: 40px;
    display: grid;
    place-content: center;
    color: rgb(255,140,8);
    transition-duration: 500ms;
  }

  .header-mob {
    background-color: rgb(255,254,250);
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: calc(5vw - 10px);
    padding-right: 5vw;
    height: 80px;
    position: fixed;
    top: 0;
    transition-property: margin-top;
    transition-duration: 500ms;
    z-index: 998;
    border-bottom: 1px solid rgb(255,140,8);
  }

  .hamburger:hover {
    cursor: pointer;
  }

}