@keyframes spinPlus {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(180deg);
    }
  }

  @keyframes spinNeg {
    from {
      transform: rotate(-180deg);
    }

    to {
      transform: rotate(0deg);
    }
  }

  .spin12-3 {
    animation-name: spinPlus;
    animation-duration: 500ms;
    animation-timing-function: ease-in;
  }

  .spin9-12 {
    animation-name: spinNeg;
    animation-duration: 500ms;
    animation-timing-function: ease-out;
  }

  @keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
  }

  @keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
  }

  .fade-in {
    animation-name: fade-in;
    animation-duration: 500ms;
    animation-timing-function: ease;
    opacity: 1;
  }

  .fade-out {
    animation-name: fade-out;
    animation-duration: 500ms;
    animation-timing-function: ease;
    opacity: 0;
  }

.menu {
  height: 100svh;
  padding-block: 120px;
  width: 100svw;
  position: fixed;
  top: 0;
  overflow: scroll;
  z-index: 999;
  background-color: rgb( 248,207,212);
}

.close-menu {
    font-size: 30px;
    background-color: rgb(255,228,236);
    border: none;
    width: 40px;
    height: 40px;
    display: grid;
    place-content: center;
    color: rgb(242,162,171);
  }

.close-menu:hover {
  cursor: pointer;
}

.header-menu {
    background-color: transparent;
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5vw;
    height: 80px;
    z-index: 999;
    position: fixed;
    top: 0;
}

.menu-nav {
    width: 100%;
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style: none;
    gap: 20px;
}

.menu-socials {
    width: 100%;
    display: flex; 
    align-items: center;
    justify-content: center;
    list-style: none;
    gap: 20px;
    margin-top: 20px;
}

.menu li a:not(.email-link) {
    color: rgb(51,51,50);
    text-overflow: ellipsis;
    font: normal normal normal 20px/1.5em lulo-clean-w01-one-bold,lulo-clean-w05-one-bold,sans-serif;
}

.menu li a:hover, .menu li a:active, .menu li a:focus {
    color: rgb(255,255,255);
}

.display-none {
    display: none;
}