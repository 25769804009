

.contact-body {
    background-color: var(--blue);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-top: 80px;
    padding-bottom: calc(var(--spacer)/2);
}
.contact-body h1 {
    color: rgb(38,53,57);
    padding-inline: 5vw;
    text-align: center;
    font-size: 42px;
    letter-spacing: -0.1em;
    font-family: lulo-clean-w01-one-bold,lulo-clean-w05-one-bold,sans-serif;
}

.contact-body p {
    line-height: 1.75em;
    text-align: center;
    font-family: helvetica-w01-light,helvetica-w02-light,sans-serif;
    font-size: 18px;
    padding-inline: 5vw;
}

.contact-block {
    width: 90vw;
    display: flex;
}

.contact-details {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: rgb(255, 255, 255);
    padding-block: 40px;
}

.contact-block img {
    width: 50%;
    object-fit: cover;
}

.contact-details h1 {
    color: var(--yellow);
    padding-inline: 5vw;
    text-align: center;
    font: normal normal normal 42px lulo-clean-w01-one-bold,lulo-clean-w05-one-bold,sans-serif;
}

.contact-details h2 {
    color: rgb(38,53,57);
    text-align: center;
    font: normal normal normal 15px/1.875em helvetica-w01-light,helvetica-w02-light,sans-serif;
}

.contact-details p {
    line-height: 1.875em;
    text-align: center;
    padding: 0;
    color: rgb(38,53,57);
    font: normal normal normal 15px/1.875em helvetica-w01-light,helvetica-w02-light,sans-serif;
}

.contact-details p:hover {
    cursor: pointer;
    text-decoration: underline;
}

.contact-list {
    display: flex;
    gap: 0.3em;
    list-style: none;
    color: rgb(255,140,8);
    font-size: 25px;
    font-family: lulo-clean-w01-one-bold,lulo-clean-w05-one-bold,sans-serif;
    line-height: 1em;
    text-align: end;
}

.Calendly {
    margin-block: 40px;
    width: 90vw;
    max-width: 1000px;
    height: 750px;
    border: none;
}

.Calendly iframe {
    border: none;
}

@media only screen and (max-width: 1300px) {
    .contact-details h1 {
        font-size: 32px;
    }
}

@media only screen and (max-width: 1115px) {
    .contact-block {
        flex-direction: column;
    }

    .contact-block img {
        display: none;
    }

    .contact-details {
        width: 100%;
        padding-block: 40px;
    }

    .Calendly {
        height: 1100px;
    }
}

@media only screen and (max-width: 900px) {
    .contact-body {
        padding-top: 40px;
        gap: 0px;
    }

    .contact-body p {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 750px) {
    .contact-body h1 {
        font-size: 32px;
    }

}

@media only screen and (max-width: 720px) {

    .Calendly {
        height: 975px;
    }
}
