@keyframes fade-in {
from {
opacity: 0;
}

to {
opacity: 1;
}
}

@font-face {
font-family: "Helvetica-W01-Light";
src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/717f8140-20c9-4892-9815-38b48f14ce2b.eot?#iefix");
src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/717f8140-20c9-4892-9815-38b48f14ce2b.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/03805817-4611-4dbc-8c65-0f73031c3973.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d5f9f72d-afb7-4c57-8348-b4bdac42edbb.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/05ad458f-263b-413f-b054-6001a987ff3e.svg#05ad458f-263b-413f-b054-6001a987ff3e") format("svg");
font-display: block;
}
@font-face {
font-family: "Helvetica-W02-Light";
src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ff80873b-6ac3-44f7-b029-1b4111beac76.eot?#iefix");
src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ff80873b-6ac3-44f7-b029-1b4111beac76.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/80c34ad2-27c2-4d99-90fa-985fd64ab81a.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b8cb02c2-5b58-48d8-9501-8d02869154c2.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/92c941ea-2b06-4b72-9165-17476d424d6c.svg#92c941ea-2b06-4b72-9165-17476d424d6c") format("svg");
font-display: block;
}
@font-face {
  font-family:"Lulo-Clean-W01-One-Bold";
  src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/aee74cb3-c913-4b54-9722-6001c92325f2.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/80de9d5d-ab5f-40ce-911b-104e51e93d7c.woff") format("woff");
font-display: block;
}
@font-face{
  font-family:"Lulo-Clean-W05-One-Bold";
  src: url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Lulo_Clean/v1/LuloCleanW05-OneBold.woff2") format("woff2"),
  url("//static.parastorage.com/services/santa-resources/resources/viewer/user-site-fonts/fonts/Lulo_Clean/v1/LuloCleanW05-OneBold.woff") format("woff");
font-display: block;
}

:root {
  --spacer: 40px;
}

* {
margin: 0;
padding: 0;
box-sizing: border-box;
}

.body {
min-height: calc(100svh - 178px);
width: 90dvw;
max-width: 980px;
margin-inline: auto;
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(auto, 1fr);
gap: var(--spacer);
padding-top: 80px;
padding-bottom: var(--spacer);
}

.thumbnail {
  margin-inline: auto;
display: flex;
gap: 5px;
flex-direction: column;
padding-bottom: var(--spacer);
border-bottom: 1px solid rgba(105, 105, 105, 0.5);
}

.thumbnail-pic {
  max-width: 100dvw;
width: 100%;
aspect-ratio: 454/255;
object-fit: contain;
}

.text {
width: 100%;
margin-inline: auto;
display: flex;
flex-direction: column;
justify-content: space-between;
}

.details {
display: flex;
display: flex;
align-items: center;
justify-content: flex-start;
gap: 5px;
}

.profile-img {
width: 40px;
height: 40px;
object-fit: cover;
border-radius: 50%;
}

.credits {
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
}

.author {
font-size: 14px;
font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
display: flex;
align-items: center;
justify-content: flex-start;
}

.times {
font-size: 11px;
font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
}

.title {
font-size: 25px;
font-family: lulo-clean-w01-one-bold, lulo-clean-w05-one-bold,
sans-serif;
vertical-align: middle;
line-height: 1.2em;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 3;
overflow: hidden;
text-overflow: ellipsis;
text-align: left;
letter-spacing: 1pt;
width: 100%;
}

.snippet {
font-size: 16px;
color: rgba(75, 75, 75);
font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
line-height: 1.5em;
display: flex;
justify-content: flex-start;
align-items: flex-start;
text-align: justify;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 3;
overflow: hidden;
text-overflow: ellipsis;
width: 100%;
}

.searchBar {
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  height: 36px;
  width: 90vw;
  max-width: 980px;
}

.searchBarInput {
  font-size: 18px;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
  outline: none;
  border: none; 
  border-bottom: 1px solid rgba(105, 105, 105, 0.5);
  width: 100%;
  height: 36px;
  }

@media only screen and (max-width: 880px) {
  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100dvw;
  }

  .searchBar {
    width: 90dvw;
  }

  .thumbnail-pic {
    width: 100%;
  }

  .thumbnail {
    width: 508px;
    max-width: 100dvw;
  }
}

@media only screen and (max-width: 580px) {
  .post-body h1, .details, .title, .snippet {
    width: 90dvw;
    margin-inline: auto;
}

.thumbnail {
  width: 100dvw;
}

}

p, h1, h2, h3, h4, li, figure {
  margin: 0;
  padding: 0;
}

.post-body {
  min-height: calc(100svh - 178px);
  max-width: 750px;
  width: 90dvw;
  margin-inline: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--spacer);
}

@media only screen and (max-width: 1111px) {        
  .searchBar {
  top: 100px;
}
.post-body {
  min-height: calc(100svh - 80px);
}
}

.post-body .top-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid rgba(75, 75, 75, 0.5);
  padding-block: 10px;
  position: relative;
}
.post-body .details {
  display: flex;
  align-items: center;
  gap: 10px;
}

.post-body .author {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
}

.post-body .authorName {
  font-size: 14px;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
}

.post-body .author-pic {
  width: 60px;
  height: 60px;
  border-radius: 50%;;
}

.post-body .time {
  font-size: 11px;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
}

.block-content {
  display: flex;
  flex-direction: column;
  width: 100dvw;
  max-width: 750px;
}

.block-content a {
  text-decoration: underline;
  color: var(--orange);
}

.block-content blockquote {
  border-left: 2px solid var(--orange);
  font-size: 18px;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
  color: rgba(75, 75, 75);
  line-height: 1.5em;
  margin-bottom: var(--spacer);
  padding-left: 20px;
}

.post-body h1 {
  font-size: 34px;
  font-family: lulo-clean-w01-one-bold, lulo-clean-w05-one-bold,
      sans-serif;
  line-height: 1.5em;
  letter-spacing: 1pt;
  width: 100%;
  color: rgba(61,41,43,1);
  margin-block: var(--spacer);
}

.block-content h2 {
  font-size: 24px;
  font-family: lulo-clean-w01-one-bold, lulo-clean-w05-one-bold,
      sans-serif;
  line-height: 1.2em;
  width: 100%;
  color: rgba(61,41,43,1);
  margin-block: 10px;

}

.block-content h3 {
  font-size: 22px;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
  text-align: left;
  margin-block: 10px;
}

.block-content h4 {
  font-size: 20px;
  font-family: lulo-clean-w01-one-bold,lulo-clean-w05-one-bold,sans-serif;
  line-height: 1.5em;
  color: rgba(61,41,43,1);
  letter-spacing: -0.1em;
  width: 100%;
  margin-block: 10px;
}

.block-content p {
  font-size: 18px;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
  color: rgba(75, 75, 75);
  line-height: 1.5em;
  margin-bottom: var(--spacer);
}

.block-content em {
  font-size: 18px;
  font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
  color: rgba(61,41,43,1);
  line-height: 1.4em;
}

.block-content figure {
  width: 100%;
  text-align: center;
}

.block-content img{
  object-fit: contain;
  margin-bottom: var(--spacer);
  margin-inline: auto;
  max-width: 100dvw;
}

@media only screen and (max-width: 500px) {
  .block-content img {
      width: 100dvw;
  }
}

@media only screen and (max-width: 850px) {

  .post-body h1 {
      font-size: 28px;
      letter-spacing: -0.1em;
  }

  .block-content h2, 
  .block-content h2, 
  .block-content h2, 
  .block-content p {
    padding-inline: min(40px, 5dvw);
  } 

}

.blog-socials {
  border-top: 1px solid rgba(75, 75, 75, 0.5);
  border-bottom: 1px solid rgba(75, 75, 75, 0.5);;
  padding-block: 20px;
  width: 90vw;
  max-width: 750px;
  margin-inline: auto;
  margin-top: calc( -1 * var(--spacer));
  margin-bottom: var(--spacer);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacer);
} 

.blog-socials ul {
  display: flex;
  gap: calc(var(--spacer) / 2);
  list-style: none;
}

.blog-socials ul li a, .blog-socials ul li button {
  color: rgba(75, 75, 75, 1);
  transition-duration: 250ms;
  height: 100%;
  display: flex;
  align-items: center;
  background: none;
  border: none;
}

.blog-socials ul li a:hover, .blog-socials ul li a:not(.share):hover, .blog-socials ul li button:hover {
  cursor: pointer;
  color: rgb(151,210,227);
}

.socials-contact button {
font-family: lulo-clean-w01-one-bold,lulo-clean-w05-one-bold,sans-serif;
font-size: 13px;
padding-block: 0.5em;
padding-inline: 1em;
border-radius: 7px;
color: rgb(0, 0, 0);
border-color: transparent;
background-color: #F2A2AB;
transition: all 0.2s ease, visibility 0s;
}

.socials-contact button:hover {
border-radius: 5rem;
background-color: rgb(251,242,190);
cursor: pointer;
}

.share {
margin-right: -10px;
font-size: 18px;
font-family: helvetica-w01-light, helvetica-w02-light, sans-serif;
color: rgba(75, 75, 75);
padding-block: 0.5em;
}

@media only screen and (max-width: 500px) {
.blog-socials {
  flex-direction: column;
  gap: calc(var(--spacer) / 2);
  align-items: flex-start;
}
}
